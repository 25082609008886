import { Link } from 'gatsby'
import React from 'react'

import FooterMenu from '../components/FooterMenu'
import Header from '../components/Header'
import InfoUseTable from '../components/InfoUseTable'
import Layout from '../components/Layout'
import PageContent from '../components/PageContent'
import SEO from '../components/SEO'

const PrivacyPage = ({ location }) => (
  <Layout location={location}>
    <PageContent>
      <Header />
      <SEO title='Privacy Policy' />
      <div className='plain-text'>
        <div className='heading'>Privacy Statement</div>
        <div className='divider' />
        <h4>Last updated March 11, 2021</h4>
        <p>
          Thank you for visiting TheBiz.pro <Link to='/'>(the “Site“)</Link> .
          The Site is owned and operated by Synonym Software Ltd. (
          <strong>“Synonym”</strong>, <strong>“we,”</strong>{' '}
          <strong>“our,”</strong> or <strong>“us”</strong> ).
        </p>
        <p>
          This privacy notice (<strong>“Privacy Statement”</strong>) sets out
          the basis on which any Personal Information we collect from you (
          <strong>“you”</strong>, <strong>“your”</strong>, or{' '}
          <strong>“user”</strong>), or that you provide to us, will be processed
          by us. By
          <strong>“Personal Information”</strong>, we mean any information
          which, either alone or in combination with other data, enables you to
          be directly or indirectly identified, for example your name, email
          address, username, contact details or any unique identifier such as an
          IP address, device ID or other online identifier.
        </p>
        <p>
          Capitalized terms that are not defined in this Privacy Statement shall
          have the meaning ascribed in our
          <Link to='/terms'>Terms of Use</Link>.
        </p>
        <p>
          Please read the following carefully to understand what Personal
          Information we collect, how it is used and the ways it can be shared
          by us.
        </p>
        <p>
          The data controller of your Personal Information is Synonym Software
          Ltd., a company which is registered in the BVI under company number
          <strong>
            {' '}
            2056692. Its EU representative is: ORWL Avocats, whose registered
            office is at 57, rue de Réaumur, 75002 Paris, France -{' '}
            <a href='mailto:synonym.data@orwl.fr'>synonym.data@orwl.fr</a>.
          </strong>
        </p>

        <h3>1. Our Processing of Your Personal information </h3>
        <InfoUseTable />

        <p>
          When we say that we think we can process your data, it is because we
          considered we have a legitimate interest (for ourselves or of a third
          party) to do so; it is in the interest of our organization to conduct
          and manage our business ; this includes{' '}
          <strong>ensuring the functioning of our Site</strong>. We might have
          legitimate interest to process Personal Information in other contexts.
          We ensure that we balance any potential impact on you and your rights
          before we process your Personal Information on that basis. You can
          obtain further information about how we assess our legitimate
          interests against any potential impact on you in respect of specific
          activities by contacting us. Please also consult the section “Your
          rights” below.
        </p>
        <p>
          It is important that the Personal Information We collect from You is
          accurate and current. Please keep us informed if your Personal
          Information changes during your relationship with us. You can do so by
          writing to us at{' '}
          <a href='mailto:privacy@synonym.to'>privacy@synonym.to</a>. We will
          endeavor to effect those changes within a reasonable timeframe.
        </p>
        <p>
          If you do not wish for your Personal Information to be used in the
          ways described within this Privacy Statement then you should not use
          the Site, access our podcast or use the functions, or features offered
          on the Site (including the crowdfunding component). Where we need
          information from you, failure to provide such information will mean
          that you will not get access to the Site, our podcast and other
          functions available on the Site.
        </p>

        <h3>2. Security and Confidentiality.</h3>
        <p>
          Synonym is committed to protecting your privacy. Internally, only
          people with a business need to know Personal Information, or whose
          duties reasonably require access to it, are granted access to users’
          Personal Information. Such individuals will only process your Personal
          Information on our instructions and are subject to a duty of
          confidentiality. We audit our personal compliance regularly.
        </p>
        <p>
          The Site’s systems and data are reviewed periodically to ensure that
          you are getting a quality service and that leading security features
          are in place. We have put in place procedures to deal with any actual
          or suspected data breach and we will use our best efforts to notify
          you and any applicable regulator of a breach where we are legally
          required to do so.
        </p>
        <p>
          Synonym takes all reasonable endeavors to protect and safeguard
          Personal Information, but there are protective measures you should
          take, as well. Do not share your Personal Information with others
          unless you clearly understand the purpose of their request for it and
          you know with whom you are dealing. Do not keep sensitive Personal
          Information in your e-mail inbox or on Webmail. If you are asked to
          assign passwords to connect you to your Personal Information, you
          should use a secure password and always use two-factor authentication
          (2FA), where available. You should change your password regularly.
        </p>

        <h3>3. What and with whom we share your Personal Information</h3>
        <p>As necessary, we will share your Personal Information with:</p>
        <ul>
          <li>
            a) Any member of our group, which means our direct or indirect
            subsidiaries, our ultimate holding company and any other subsidiary
            of that holding company, including their respective shareholders,
            directors, officers, affiliates, employees, contractors, agents,
            partners, insurers, and attorneys or representatives.
          </li>
          <li>
            b) Our service providers and platform providers, to the extent
            necessary to conduct our business.
          </li>
          <li>
            c) Selected third parties, including analytics and search engine
            providers that assist us in the improvement and optimization of the
            Site.
          </li>
          <li>
            d) Authorities and law enforcement agencies worldwide either when
            ordered to do so or on a voluntary basis if this appears reasonable
            and necessary to us.
          </li>
        </ul>
        <p>We also disclose your Personal Information to third parties:</p>
        <ul>
          <li>
            a) If Synonym or substantially all of its assets are acquired by a
            third party, in which case Personal Information held by it about its
            users will be one of the transferred assets.
          </li>
          <li>
            b) If we are under a duty to disclose or share your Personal
            Information in order to comply with any legal obligation, or in
            order to enforce or apply our Terms of Use and other agreements; or
            to protect the rights, property, or safety of us, our clients, or
            others, including to defend ourselves from legal claims.
          </li>
        </ul>
        <p>
          You can find more information on the actual parties we are sharing
          your data with <Link to='#info-use-table'>here</Link> The list is
          updated regularly.
        </p>

        <h3>4. Transfers</h3>
        <p>
          Your Personal Information will be stored in the European Economic Area
          (“EEA”) and may be transferred worldwide.
        </p>
        <p>
          Personal Information and other data may therefore be exported outside
          of the jurisdiction in which you reside. Your Personal Information may
          be processed and stored in a foreign country or countries. Under those
          circumstances, the governments, courts, law enforcement, or regulatory
          agencies of that country or those countries may be able to obtain
          access to your Personal Information through foreign laws. You need to
          be aware that the privacy standards of those countries may be lower
          than those of the jurisdiction in which you reside.
        </p>
        <p>
          EEA Users. We will take all steps reasonably necessary to ensure that
          your Personal Information is treated securely and in accordance with
          this Privacy Statement. All data you provide to us is stored on our
          secure servers. Where we transfer our data outside of the EEA, we
          ensure that adequate safeguards are in place. That includes, where
          necessary, taking steps to evaluate the risks raised by the transfers
          in countries that do not offer an adequate level of protection. Please
          contact us if you want further information on the specific mechanism
          used by us when transferring your Personal Information out of the EEA.
        </p>

        <h3>5. Data Retention</h3>
        <p>
          We have data retention and deletion policies designed to retain
          Personal Information for no longer than necessary for the purposes set
          out herein or as otherwise required to meet legal or business needs.
          Because of those retention requirements, we might not be able to honor
          erasure requests.
        </p>

        <h3>6. Your rights</h3>
        <p>
          All Users. You may access and verify your Personal Information held by
          Synonym by submitting a written request to: Synonym Software Ltd. c/o
          ORWL Avocats whose registered office is at 57, rue Réaumur, 75002,
          Paris, France -{' '}
          <strong>
            <a href='mailto:synonym.data@orwl.fr'>synonym.data@orwl.fr</a>
          </strong>
          .
        </p>
        <p>
          EEA Users. You have a number of rights in relation to how we process
          your Personal Information. You may exercise these rights by contacting
          us (see details below). These include the right to:
        </p>
        <ul>
          <li>
            a) access the Personal Information that we may hold about you;
          </li>
          <li>
            b) rectify any inaccurate Personal Information that we may hold
            about you;
          </li>
          <li>
            c) have your Personal Information erased in certain circumstances,
            for example, where it is no longer necessary for us to process your
            Personal Information to fulfill our processing purposes; or where
            you have exercised your right to object to the processing;
          </li>
          <li>
            d) restrict the processing of your Personal Information where, for
            example, the information is inaccurate or it is no longer necessary
            for us to process such information or where you have exercised your
            right to object to our processing;
          </li>
          <li>
            e) object to the processing of your Personal Information which may
            be exercised in certain circumstances, for example, where we are
            processing your Personal Information for direct marketing purposes,
            or where your own legitimate interests outweigh ours;
          </li>
          <li>
            f) have your Personal Information ported to a new service provider.
            You may exercise these rights by contacting us (see details below);
          </li>
          <li>
            g) when we rely on your consent to process Personal Information
            (some cookies and communications), you have the right to withdraw
            your consent at any time. For cookies, this is done by setting up
            the cookie’s preferences. Please visit our{' '}
            <Link to='/cookies'>Cookies Policy</Link>.
          </li>
        </ul>
        <p className='underline'>No fee usually required.</p>
        <p>
          You will not have to pay a fee to access your Personal Information or
          to exercise any of your other rights. We may charge a reasonable fee
          if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we may refuse to comply with your request in these
          circumstances.
        </p>
        <p className='underline'>What we may need from you.</p>
        <p>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your Personal
          Information (or to exercise any of your other rights). This is a
          security measure to ensure that Personal Information is not disclosed
          to any person who has no right to receive it. We may also contact you
          to ask you for further information in relation to your request to
          speed up our response.
        </p>
        <p className='underline'>Time limit to respond.</p>
        <p>
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>

        <h3>7. Changes to this Privacy Statement</h3>
        <p>
          Any changes we make to our Privacy Statement in the future will be
          posted on this page. Please check back frequently to see any updates
          or changes to our Privacy Statement.
        </p>
        <h3>8. Contact</h3>
        <p>
          Questions, comments and requests regarding this Privacy Statement
          should be addressed by email to{' '}
          <strong>
            <a href='mailto:privacy@synonym.to'>privacy@synonym.to</a>
          </strong>
          .
        </p>
        <p>
          <span className='underline'>EEA Users.</span>
          Should you have any concerns about how we handle your Personal
          Information, please contact us in the first instance{' '}
          <strong>
            by email at{' '}
            <a href='mailto:privacy@synonym.to'>privacy@synonym.to</a>
            {/* eslint-disable-next-line react/jsx-closing-tag-location */}
          </strong>
          . You can also contact our EU representative, ORWL Avocats whose
          registered office is at 57, rue Réaumur, 75002, Paris, France -{' '}
          <strong>
            <a href='mailto:synonym.data@orwl.fr'>synonym.data@orwl.fr </a>
          </strong>
          . You can also submit a complaint to the national supervisory
          authority within your jurisdiction, details of which can be found
          online.
        </p>
        <h4>
          <strong>Version 1.0</strong>
        </h4>
      </div>
      <FooterMenu className='page-content__footer' />
    </PageContent>
  </Layout>
)

export default PrivacyPage
