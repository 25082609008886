import React from 'react'

import Img1 from '../../images/1.png'
import Img2 from '../../images/2.png'
import Img3 from '../../images/3.png'

export default function InfoUseTable() {
  return (
    <div className='info-use-table-wrapper'>
      <table id='info-use-table'>
        <thead>
          <tr>
            <th rowSpan='2'>Purpose</th>
            <th colSpan='2'>Personal Information</th>
            <th rowSpan='2' className='width'>
              Legal basis
            </th>
          </tr>

          <tr>
            <th className='green'>
              <img src={Img1} alt='img' className='img1' />
            </th>
            <th className='green flex-center no-borders'>
              <img src={Img2} alt='img' className='img2' />
              <img src={Img3} alt='img' className='img3' />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              Establish and maintain a relationship with you (creation and
              management of your account with us, including dealing with your
              request to make account changes)
            </td>
            <td>
              First name, last name, email address, username, password, time
              zone
            </td>
            <td rowSpan='2'>
              Technical information, including the Internet protocol (IP)
              address used to connect your computer or other device to the
              Internet, browser type and version, time zone setting, browser
              plug-in types and versions, operating system and platform
              <br />
              Information about your visit, including the dates and times you
              use the Site, length of visits to certain pages, page interaction
              information (such as scrolling, clicks, and mouse-overs), methods
              used to browse away from the page, IP addresses, logs history
            </td>
            <td rowSpan='2' className='medium center blue'>
              You are using us
              <br />
              <span className='italic'>(performance of a contract)</span>
            </td>
          </tr>

          <tr>
            <td className='green'>
              Interact with you, allow you to listen to our podcasts and provide
              Contributions to unlock our episodes
            </td>
            <td className='green'>User name, email address, wallet address</td>
          </tr>

          <tr>
            <td>
              Administer the website, system administration and detecting usage
              patterns (including broad demographic information for aggregated
              use)
            </td>
            <td>N/A</td>
            <td>
              IP address, browser type, access times, URL requested and referral
              URL
            </td>
            <td rowSpan='5' className='medium center yellow'>
              We think we can
              <br />
              <span className='italic'>(legitimate interest)</span>
            </td>
          </tr>

          <tr>
            <td className='green'>Detection for troubleshooting purposes</td>
            <td className='green'>N/A</td>
            <td className='green'>
              Technical information, including the Internet protocol (IP)
              address used to connect your computer or other device to the
              Internet, browser type and version, time zone setting, browser
              plug-in types and versions, operating system and platform
              <br />
              Information about your visit, including the dates and times you
              use the Site length of visits to certain pages, page interaction
              information (such as scrolling, clicks, and mouse-overs), and
              methods used to browse away from the page
            </td>
          </tr>

          <tr>
            <td>Protect the right, property, safety of us, or others</td>
            <td>
              Any of the Personal Information in this table, taking into account
              necessity and data minimization
            </td>
            <td>N/A</td>
          </tr>

          <tr>
            <td className='green'>
              Inform you on the developments of our project, conduct surveys and
              get feedbacks from you
            </td>
            <td className='green'>Contact details</td>
            <td className='green'>N/A</td>
          </tr>

          <tr>
            <td>
              If you are a podcast participant, advert and share your details on
              our Site, as well as with other platforms (including social media)
            </td>
            <td>
              Picture, contact details, first name and last name, function,
              organization you are belonging to, video or sound footage.
            </td>
            <td>N/A</td>
          </tr>

          <tr>
            <td className='green'>
              Provide you with news and communications about topics that might
              be of interest to you
            </td>
            <td className='green'>Contact details</td>
            <td className='green'>
              IP address, other technical information through cookies and other
              tracking technologies
            </td>
            <td className='medium gray center'>
              You agreed to it <br />
              <span className='italic'>(consent)</span>
            </td>
          </tr>

          <tr>
            <td>Meet our legal requirements</td>
            <td>
              Any of the Personal Information in this table, taking into account
              necessity and data minimization
            </td>
            <td>
              Technical information, including the Internet protocol (IP)
              address used to connect your computer or other device to the
              Internet, browser type and version, time zone setting, browser
              plug-in types and versions, operating system and platform
              <br />
              Information about your visit, including the dates and times you
              use the Site length of visits to certain pages, page interaction
              information (such as scrolling, clicks, and mouse-overs), methods
              used to browse away from the page
            </td>
            <td className='medium orange center'>
              Compliance with a legal requirement
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
